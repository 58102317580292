<template>
<v-app style="background-color: #0D47A1; font-family: Poppins">
    <Snowf :amount="100" :size="5" :speed="1.5" :wind="0.5" :opacity="0.8" :swing="1" :image="null" :zIndex="null" :resize="true" color="#fff" />
    <v-main>
        <router-view />
    </v-main>
    <v-img src="https://christmas.hopekl.org/2020/ft-01.png"></v-img>
</v-app>
</template>

<script>
import Snowf from 'vue-snowf'

export default {
  name: 'App',
  components: {
    Snowf
  },
  data: () => ({
    //
  })
}
</script>
