var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',_vm._g(_vm._b({attrs:{"max-width":"500"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-card',{staticClass:"rounded-xl"},[_c('v-card-title',{staticClass:"text-h5 font-weight-bold mb-2"},[_vm._v(" Reservation Form ")]),_c('v-card-text',[_c('v-row',[_c('validation-observer',{ref:"observer"},[_vm._l((_vm.users),function(user,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"rounded-xl",staticStyle:{"background-color":"#f5f5f5"},attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("Attendee "+_vm._s(index+1)+" details "),_c('v-spacer'),(index != 0)?_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteUser(index)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1),_c('v-card-text',[_c('validation-provider',{attrs:{"vid":(index + "-name"),"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","prepend-inner-icon":"mdi-account","outlined":"","dense":"","error-messages":errors},model:{value:(user.name),callback:function ($$v) {_vm.$set(user, "name", $$v)},expression:"user.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":(index + "-email"),"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","type":"email","prepend-inner-icon":"mdi-email","outlined":"","dense":"","error-messages":errors},model:{value:(user.email),callback:function ($$v) {_vm.$set(user, "email", $$v)},expression:"user.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"vid":(index + "-mobile"),"name":"Mobile","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Mobile","type":"tel","prepend-inner-icon":"mdi-phone","outlined":"","dense":"","error-messages":errors},model:{value:(user.mobile_no),callback:function ($$v) {_vm.$set(user, "mobile_no", $$v)},expression:"user.mobile_no"}})]}}],null,true)}),_c('label',{staticClass:"font-weight-medium"},[_vm._v("Age Range")]),_c('validation-provider',{attrs:{"vid":(index + "-age-range"),"name":"Age Range","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-1",attrs:{"row":"","error-messages":errors},model:{value:(user.age_range),callback:function ($$v) {_vm.$set(user, "age_range", $$v)},expression:"user.age_range"}},[_c('v-radio',{attrs:{"label":"4-6","value":"4-6"}}),_c('v-radio',{attrs:{"label":"7-12","value":"7-12"}}),_c('v-radio',{attrs:{"label":"13-99","value":"13-99"}})],1)]}}],null,true)}),_c('label',{staticClass:"font-weight-medium"},[_vm._v("Attendance Type")]),_c('validation-provider',{attrs:{"vid":(index + "-attendance-type"),"name":"Attendance Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-1",attrs:{"row":"","error-messages":errors},model:{value:(user.is_physical),callback:function ($$v) {_vm.$set(user, "is_physical", $$v)},expression:"user.is_physical"}},[_c('v-radio',{attrs:{"label":"Physical","value":"true"}}),_c('v-radio',{attrs:{"label":"Online","value":"false"}})],1)]}}],null,true)})],1)],1)],1)}),_c('v-col',{attrs:{"cols":"12"}},[(_vm.users.length < _vm.maximumUser)?_c('v-btn',{staticClass:"pa-5",attrs:{"elevation":"0","color":"secondary","rounded":""},on:{"click":_vm.addUser}},[_c('v-icon',[_vm._v("mdi-plus")]),_c('v-icon',[_vm._v("mdi-account")])],1):_vm._e()],1)],2)],1)],1),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"font-weight-bold pa-6",attrs:{"elevation":"0","color":"error","rounded":"","x-large":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('v-spacer')],1)],1),(_vm.responseDialogData)?_c('ResponseDialog',{attrs:{"data":_vm.responseDialogData},on:{"on-cancel":_vm.responseClose},model:{value:(_vm.responseDialog),callback:function ($$v) {_vm.responseDialog=$$v},expression:"responseDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }