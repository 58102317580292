<template>
<v-dialog width="400" max-width="500" v-bind="$attrs" v-on="$listeners">
    <v-card class="rounded-xl pa-3">
        <div style="display: block; text-align: center">
            <v-avatar size="100" class="mb-0 ma-6">
                <v-img v-if="data.success === false" :src="errorIcon"></v-img>
                <v-img v-else :src="successIcon"></v-img>
            </v-avatar>
        </div>
        <div v-if="data.success === false">
            <v-card-title class="headline d-flex justify-center font-weight-black">
                Ops...
            </v-card-title>
            <v-card-text class="text-center">
                {{ data.error }}
            </v-card-text>
        </div>
        <div v-else>
            <v-card-title class="headline d-flex justify-center font-weight-black">
                Congratulation!
            </v-card-title>
            <v-card-text class="text-center" v-if="data.data.successful">
                <p v-if="data.data.successful.length > 0">Successfully added: {{data.data.successful.toString()}}</p>
                <p v-if="data.data.duplicate.length > 0">Duplicate found and skipped: {{data.data.duplicate.toString()}}</p>
            </v-card-text>
            <v-card-text class="text-center" v-else>
              <p>{{data.data}}</p>
            </v-card-text>
        </div>
        <v-card-actions class="justify-end">
            <v-btn text @click="$emit('on-cancel')">Close</v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
export default {
  name: 'ResponseDialog',
  props: {
    success: {
      type: Boolean,
      default: true
    },
    message: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      successIcon: 'https://www.hopekl.org/assets/icon/check-circle.gif',
      errorIcon: 'https://www.hopekl.org/assets/icon/check-cancel.png'
    }
  }
}
</script>
