<template>
<v-container class="white--text">
    <v-row class="text-center mt-1" no-gutters>
        <v-col cols="12">
            <a href="https://hopekl.org"><v-img class="mx-auto" src="https://hopekl.org/assets/logo/hope_kl_logo_new_white.png" lazy-src="https://hopekl.org/assets/logo/hope_kl_logo_new_white.png" max-width="200"></v-img></a>
            <v-img class="mx-auto" src="../assets/wonderfulGod.png" max-width="300" style="margin-top: -10px; margin-bottom: -30px"></v-img>
        </v-col>
        <v-col cols="12" style="margin-top: -10px">
            <p>
                Psalm 139<br>
                Date: 19th December 2021<br>
                Time: 10AM<br>
                Venue: Hope KL
            </p>
        </v-col>
        <v-col>
          <iframe width="640" height="360"
                                src="https://www.youtube.com/embed/d7aaZ7l-hKY?showinfo=0" frameborder="0"
                                allowfullscreen
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </v-col>
        <v-col cols="12">
            <v-container class="d-flex justify-center text-left">
                <v-card color="transparent" flat max-width="200">
                    <v-list-item two-line>
                        <v-list-item-icon>
                            <v-icon dark x-large>mdi-sofa-single-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content style="margin-left: -15px">
                            <v-list-item-title class="white--text font-weight-bold">{{ totalSeat - eventData.audience.physical }} Seats</v-list-item-title>
                            <v-list-item-subtitle class="white--text">Available</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
                <v-card color="transparent" flat max-width="200">
                    <v-list-item two-line>
                        <v-list-item-icon>
                            <v-icon dark x-large>mdi-youtube-tv</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content style="margin-left: -15px">
                            <v-list-item-title class="white--text font-weight-bold">Live Stream</v-list-item-title>
                            <v-list-item-subtitle class="white--text">Available</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-container>
        </v-col>
        <v-col cols="12">
            <v-btn :disabled="eventData.audience.physical >= totalSeat" x-large color="error" class="font-weight-black" rounded @click="registerDialog = true">Reserve Seats Now</v-btn>
            <p class="my-6" @click="cancelDialog = true">Cancel reservation click here</p>
        </v-col>
    </v-row>
    <RegistrationDialog v-model="registerDialog" @on-complete="close"/>
    <CancelDialog v-model="cancelDialog" @on-complete="close" />
</v-container>
</template>

<style scoped>
</style>

<script>
import RegistrationDialog from './RegistrationDialog.vue'
import CancelDialog from './CancelDialog.vue'
const axios = require('axios')

export default {
  components: {
    RegistrationDialog,
    CancelDialog
  },
  name: 'HelloWorld',
  data: () => ({
    registerDialog: false,
    cancelDialog: false,
    eventSlug: 'christmas-2021',
    eventData: {
      audience: {
        physical: 0
      }
    },
    totalSeat: 250
  }),
  mounted () {
    this.getEventData()
  },
  methods: {
    async getEventData () {
      const { data } = await axios.get('https://api.hopekl.org/v1/public/event/' + this.eventSlug)
      this.eventData = data.data
    },
    close () {
      this.registerDialog = false
      this.getEventData()
    }
  }
}
</script>
