<template>
<v-dialog max-width="500" v-bind="$attrs" v-on="$listeners">
    <v-card class="rounded-xl">
        <v-card-title class="text-h5 font-weight-bold mb-2">
            Reservation Form
        </v-card-title>
        <v-card-text>
            <v-row>
                <validation-observer ref="observer">
                    <v-col cols="12" v-for="(user, index) in users" :key="index">
                        <v-card elevation="0" class="rounded-xl" style="background-color: #f5f5f5">
                            <v-card-title>Attendee {{index+1}} details <v-spacer></v-spacer>
                                <v-btn text color="error" v-if="index != 0" @click="deleteUser(index)">
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-card-title>
                            <v-card-text>
                                <validation-provider :vid="`${index}-name`" v-slot="{ errors }" name="Name" rules="required">
                                    <v-text-field label="Name" prepend-inner-icon="mdi-account" v-model="user.name" outlined dense :error-messages="errors"></v-text-field>
                                </validation-provider>
                                <validation-provider :vid="`${index}-email`" v-slot="{ errors }" name="Email" rules="required|email">
                                    <v-text-field label="Email" type="email" prepend-inner-icon="mdi-email" v-model="user.email" outlined dense :error-messages="errors"></v-text-field>
                                </validation-provider>
                                <validation-provider :vid="`${index}-mobile`" v-slot="{ errors }" name="Mobile" rules="required|numeric">
                                    <v-text-field label="Mobile" type="tel" prepend-inner-icon="mdi-phone" v-model="user.mobile_no" outlined dense :error-messages="errors"></v-text-field>
                                </validation-provider>
                                <label class="font-weight-medium">Age Range</label>
                                <validation-provider :vid="`${index}-age-range`" v-slot="{ errors }" name="Age Range" rules="required">
                                    <v-radio-group row class="mt-1" v-model="user.age_range" :error-messages="errors">
                                        <v-radio label="4-6" value="4-6"></v-radio>
                                        <v-radio label="7-12" value="7-12"></v-radio>
                                        <v-radio label="13-99" value="13-99"></v-radio>
                                    </v-radio-group>
                                </validation-provider>
                                <label class="font-weight-medium">Attendance Type</label>
                                <validation-provider :vid="`${index}-attendance-type`" v-slot="{ errors }" name="Attendance Type" rules="required">
                                    <v-radio-group row class="mt-1" v-model="user.is_physical" :error-messages="errors">
                                        <v-radio label="Physical" value="true"></v-radio>
                                        <v-radio label="Online" value="false"></v-radio>
                                    </v-radio-group>
                                </validation-provider>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-btn elevation="0" color="secondary" class="pa-5" v-if="users.length < maximumUser" rounded @click="addUser">
                            <v-icon>mdi-plus</v-icon>
                            <v-icon>mdi-account</v-icon>
                        </v-btn>
                    </v-col>
                </validation-observer>
            </v-row>
        </v-card-text>
        <v-card-actions class="pa-5">
            <v-spacer></v-spacer>
            <v-btn @click="submit()" elevation="0" color="error" rounded class="font-weight-bold pa-6" x-large>
                Submit
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
    <ResponseDialog v-if="responseDialogData" v-model="responseDialog" @on-cancel="responseClose" :data="responseDialogData"/>
</v-dialog>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'
import {
  required,
  email,
  numeric
} from 'vee-validate/dist/rules'
import ResponseDialog from './ResponseDialog.vue'
const axios = require('axios')

extend('email', email)
extend('numeric', numeric)
extend('required', {
  ...required,
  message: '{_field_} cannot be empty'
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponseDialog
  },
  name: 'RegistrationDialog',
  data () {
    return {
      responseDialog: false,
      responseDialogData: null,
      maximumUser: 7,
      eventSlug: 'christmas-2021',
      service_id: 1,
      users: [{
        service_id: 1,
        name: null,
        email: null,
        mobile_no: null,
        age_range: null,
        is_physical: null
      }]
    }
  },
  methods: {
    addUser () {
      return this.users.push({
        service_id: this.service_id,
        name: null,
        email: this.users[0].email,
        mobile_no: this.users[0].mobile_no,
        age_range: null,
        is_physical: this.users[0].is_physical
      })
    },
    deleteUser (index) {
      return this.users.splice(index, 1)
    },
    async submit () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        const {
          data
        } = await axios.post('https://api.hopekl.org/v1/public/event/' + this.eventSlug + '/register/bulk', { users: this.users })

        if (data.success) {
          this.responseDialog = true
          this.responseDialogData = data
          this.reset()
        }
      }
    },
    reset () {
      this.$refs.observer.reset()
      this.users = [{
        service_id: this.service_id,
        name: null,
        email: null,
        mobile_no: null,
        age_range: null,
        is_physical: null
      }]
    },
    responseClose () {
      this.responseDialog = false
      return this.$emit('on-complete')
    }
  }
}
</script>
