<template>
<v-dialog max-width="500" v-bind="$attrs" v-on="$listeners">
    <v-card class="rounded-xl">
        <v-card-title class="text-h5 font-weight-bold mb-2">
            Cancel Reservation Form
        </v-card-title>
        <v-card-text>
            <v-row>
                <validation-observer ref="observer">
                    <v-col cols="12" class="pt-5">
                        <validation-provider :vid="`cancel-name`" v-slot="{ errors }" name="Name" rules="required">
                                    <v-text-field label="Name" prepend-inner-icon="mdi-account" v-model="name" outlined dense :error-messages="errors"></v-text-field>
                                </validation-provider>
                    </v-col>
                </validation-observer>
            </v-row>
        </v-card-text>
        <v-card-actions class="pb-5 pt-0">
            <v-spacer></v-spacer>
            <v-btn @click="submit()" elevation="0" color="error" rounded class="font-weight-bold pa-6" x-large>
                Submit
            </v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
    <ResponseDialog v-if="responseDialogData" v-model="responseDialog" @on-cancel="responseClose" :data="responseDialogData"/>
</v-dialog>
</template>

<script>
import {
  extend,
  ValidationObserver,
  ValidationProvider
} from 'vee-validate'
import {
  required,
  email,
  numeric
} from 'vee-validate/dist/rules'
import ResponseDialog from './ResponseDialog.vue'
const axios = require('axios')

extend('email', email)
extend('numeric', numeric)
extend('required', {
  ...required,
  message: '{_field_} cannot be empty'
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ResponseDialog
  },
  name: 'RegistrationDialog',
  data () {
    return {
      responseDialog: false,
      responseDialogData: null,
      maximumUser: 7,
      eventSlug: 'christmas-2021',
      service_id: 1,
      name: null
    }
  },
  methods: {
    async submit () {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        const {
          data
        } = await axios.delete('https://api.hopekl.org/v1/public/event/' + this.eventSlug + '/revoke', { data: { name: this.name } })

        this.responseDialog = true
        this.responseDialogData = data

        if (data.success) {
          this.reset()
        }
      }
    },
    reset () {
      this.$refs.observer.reset()
      this.name = null
    },
    responseClose () {
      this.responseDialog = false
      return this.$emit('on-complete')
    }
  }
}
</script>
